import styles from './Gallery.module.css'
import sculptures from '../sculptures.json'
import { Link, useHistory } from 'react-router-dom'
import React, { useState } from 'react'

function useSearchParams() {
  const [state, setState] = useState(new URLSearchParams(window.location.search))
  const history = useHistory()
  function setParams (params) {
    setState(params)
    history.push(`?${params.toString()}`)
  }
  return [state, setParams]
}
function Tile ({ piece }) {
  return (
    <div className={styles.tile}>
      <Link to={`/sculptures/${piece.id}`} className={styles.innerTile}>
        <img
          className={styles.img}
          src={`/assets/${piece.images[0].src}`}
          alt={piece.title}
        />
        <div className={styles.info}>
          <table className={styles.infoBox}>
            <tr className={styles.tr}>
              <th className={styles.th}>
                <i>{piece.title}</i>
              </th>
            </tr>
          </table>
        </div>
      </Link>
    </div>
  )
}
function FilterSelect({ setSearchParams, searchParams }) {
  const options = {
    "all": "All",
    "public": "Public Works",
    "small": "Private Gallery Pieces",
    "installation": "Installations"
  }
  function handleChange(e) {
    e.preventDefault()
    const prevParams = [...searchParams.entries()].reduce((prev, curr) => ({...prev, [curr[0]]: curr[1]}), {})
    setSearchParams(new URLSearchParams({...prevParams, "category": e.target.value}))
  }
  return (
    <select value={searchParams.get("category")} onChange={handleChange}>
      {Object.keys(options).map(option =>
        <option value={option} key={option}>
          {options[option]}
        </option>)}
    </select>
  )
}
export default function Gallery () {
  const [searchParams, setSearchParams] = useSearchParams()
  const category = searchParams.get("category")
  console.log(category)
  return (
    <div><FilterSelect setSearchParams={setSearchParams} searchParams={searchParams}/>
    <div className={styles.gallery}>
      
      {sculptures.filter(x => !x.hidden)
        .filter(x => (category === null || category == "all") ? true : x.category === category)
        .map(piece => (
        <div className={styles.tileContainer} key={piece.id}>
          <Tile piece={piece} />
        </div>
      ))}
    </div>
    </div>
  )
}
