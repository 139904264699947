export default function Contact () {
  return (
    <div className='page'>
      <h1>Contact</h1>
      <h2>Email</h2>
      <p>
        <a href='mailto:jennifer@jcsculpture.com'>jennifer@jcsculpture.com</a>.
      </p>
      <h2>Phone</h2>
      <p>
        <a href='tel:+14083068294'>(408)-306-8294</a>
      </p>
    </div>
  )
}
