import styles from './Sidebar.module.css'

import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

function SidebarButton (props) {
  const loc = useLocation()
  const selected = loc.pathname === props.to
  let className = styles.sidebarButton

  if (selected) {
    className += ' ' + styles.sidebarButtonSelected
  }
  return (
    <Link
      className={className}
      {...props}
    />
  )
}

export default function Sidebar () {
  const [visible, setVisible] = useState(false)
  const hide = () => {
    setVisible(false)
  }
  const toggle = () => {
    setVisible(!visible)
  }

  return (
    <>
      <div className={styles[visible ? 'sidebarVisible' : 'sidebarHidden']}>
        <div>
          <h1 className={styles.title}>Jennifer Cannon</h1>
        </div>
        <br />
        <br />
        <br />
        <SidebarButton to='/' onClick={hide}> Gallery </SidebarButton>
        <SidebarButton to='/bio' onClick={hide}> Artist Statement/Bio </SidebarButton>
        <SidebarButton to='/resume' onClick={hide}> Resume/CV </SidebarButton>
        <SidebarButton to='/contact' onClick={hide}> Contact </SidebarButton>
      </div>
      <div className={styles.topbar}>
        <svg
          width='56'
          height='56'
          className={styles.hamburger}
          onClick={toggle}
        >
          <line x1='14' y1='18' x2='42' y2='18' className={styles.line} />
          <line x1='14' y1='28' x2='42' y2='28' className={styles.line} />
          <line x1='14' y1='37' x2='42' y2='37' className={styles.line} />
        </svg>
      </div>
    </>
  )
}
