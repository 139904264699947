
export default function Bio () {
  return (
    <div className='page'>
      <h1>Bio</h1>
      <h2>Artist Statement</h2>
      <p className='text'>
        My artwork has always centered around the theme of control. As the
        youngest of seven, I realized that I couldn’t control much of the world
        around me but have always strived to do so. I believe that much of what
        people are fighting for is simply a livable wage, equity, and the
        ability to pursue happiness. As individuals we are only small pieces of
        a much larger system, so we need to work together to make significant
        change. My work portrays this theme in a variety of ways such as a
        surfer riding a giant wave, or small mechanized units attempting to
        control a larger flowing form.  Recently I have depicted the alienation
        many of us feel as we struggle with the current pandemic, lack of social
        interaction, and food insecurity. As we begin to close out the first
        quarter of the twenty-first century we are in a state of great societal
        turbulence, uncertainty, and reckoning. As an artist I hope to help
        elucidate these events, capture moments for posterity, and bring people
        together by incorporating personal experiences that have universal
        meaning.
      </p>

      <h2>Artist Biography</h2>

      <p className='text'>
        Jennifer Cannon is a Bay area artist who earned her MFA from San Jose
        State University. She is best known for her outdoor public artwork of
        polished aluminum and brass which speak to our ability, or inability to
        exert control. Some of her permanent public work can be seen in Imperial
        Beach, California; Durango, Colorado; and Lampasas, Texas.
      </p><br />

      <p class='text'>
        Cannon was inspired to become a sculptor when as a small child, she
        watched her father work on farm machinery, and was attracted to the
        ingenuity and grit of transforming raw materials into something
        imagined. She uses a combination of inventiveness, perseverance, and
        dedication to craftsmanship to create each uniquely fabricated artwork.
        These artistic qualities are also evident in Cannon’s gallery work,
        where she incorporates a wide range of materials to express themes of
        control on a more intimate scale.
      </p>

      <br />
      <img
        src='assets/images/jennifer_working.jpg'
        alt='Jennifer working in her studio'
        style={{
          margin: 'auto',
          width: '100%',
          height: '320px',
          objectFit: 'contain'
        }}
      />

    </div>
  )
}
