import styles from './Piece.module.css'
import Carousel from '../components/Carousel'
import { useParams, Link } from 'react-router-dom'
import pieces from '../sculptures.json'

export default function Piece () {
  const {
    pieceId
  } = useParams()

  const piece = pieces.find(x => x.id === pieceId)

  return (
    <div className={styles.piece}>
      <h1><i>{piece.title}</i>, {piece.year}</h1>
      <h2 className={styles.h2}>{piece.build}</h2>
      {piece.whereabouts && (
        <h2>{piece.whereabouts}</h2>
      )}
      <div className='text'>
        <Carousel images={piece.images} />
        <p dangerouslySetInnerHTML={{ __html: piece.description }} />
        {piece.forSale && (
          <p>
            <i>{piece.title}</i> is currently for sale at a price of
            ${piece.price} USD. For inquiries, please visit
            the <Link to='/contact'>contact</Link> page.
          </p>
        )}
      </div>
    </div>
  )
}
