import './App.css'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import Sidebar from './Sidebar'
import Gallery from './pages/Gallery'
import Contact from './pages/Contact'
import Resume from './pages/Resume'
import Bio from './pages/Bio'
import Piece from './pages/Piece'

function App () {
  return (
    <Router>
      <div className='App'>
        <div className='content'>
          <Switch>
            <Route path='/sculptures/:pieceId'>
              <Piece />
            </Route>
            <Route path='/gallery'>
              <Redirect to='/' />
            </Route>
            <Route path='/contact'>
              <Contact />
            </Route>
            <Route path='/resume'>
              <Resume />
            </Route>
            <Route path='/bio'>
              <Bio />
            </Route>
            <Route path='/'>
              <Gallery />
            </Route>
          </Switch>
        </div>
        <Sidebar />
      </div>
    </Router>
  )
}

export default App
