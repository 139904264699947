import styles from './Resume.module.css'

export default function Resume () {
  return (
    <div className='page'>
      <h1>Resume/CV</h1>
      <p>
        Download Jennifer's
        Resume: <a href='assets/JenniferCannonResume.pdf' target='_blank'>PDF</a>.
      </p>
      <p>
        Download Jennifer's
        CV: <a href='assets/JenniferCannonCV.pdf' target='_blank'>PDF</a>.
      </p>

      <h2>CV</h2>
      <h3>Education</h3>
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <colgroup>
            <col span='1' className={styles.col1} />
            <col span='1' className={styles.col2} />
          </colgroup>
          <tr><td>San Jose State University</td><td>2022</td></tr>
          <tr><td><b>MFA – Spatial Art</b></td><td /></tr>
          <tr className={styles.blank}><td colSpan='2' /></tr>
          <tr><td>Northern California Public Art Academy</td><td>2009</td></tr>
          <tr className={styles.blank}><td colSpan='2' /></tr>
          <tr><td>Bradley University</td><td /></tr>
          <tr><td><b>B.S. Studio Art</b></td><td><b>1995</b></td></tr>
          <tr><td>Sculpture Emphasis</td><td /></tr>
          <tr><td>Bradley University</td><td /></tr>
          <tr><td><b>B.S. Marketing</b></td><td><b>1988</b></td></tr>
          <tr><td>Science and Mathematics Emphasis</td><td /></tr>
          <tr className={styles.blank}><td colSpan='2' /></tr>
        </table>
      </div>
      <h3>Awards</h3>
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <colgroup>
            <col span='1' className={styles.col1} />
            <col span='1' className={styles.col2} />
          </colgroup>
          <tr><td>Special Mention Award – Teravarna Artist Grant</td><td>2023</td></tr>
          <tr><td>Best of Show Third Place – Sioux Falls SculptureWalk</td><td>2023</td></tr>
          <tr><td>Virtuoso Sculptor Certification – Sioux Falls Sculpture Walk</td><td>2021</td></tr>
          <tr><td>Best of Show II – Sioux Falls Sculpture Walk</td><td>2021</td></tr>
          <tr><td>Artistic Merit Award – Castlegar Sculpture Walk </td><td>2018</td></tr>
          <tr><td>Best of Show II – Sioux Falls Sculpture Walk</td><td>2018</td></tr>
          <tr><td>Best of Show II – Sioux Falls Sculpture Walk</td><td>2016</td></tr>
          <tr><td>Third Prize – Sculpture for All</td><td>2015</td></tr>
          <tr><td>Director’s Award – Sioux Falls Sculpture Walk</td><td>2012</td></tr>
          <tr><td>Advanced Student Sculptor of the Year</td><td>1995</td></tr>
        </table>
      </div>
      <h3>Selected Solo Exhibitions</h3>
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <colgroup>
            <col span='1' className={styles.col1} />
            <col span='1' className={styles.col2} />
          </colgroup>
          <tr><td><i>The Gown</i> - Art Kiosk, Redwood City, CA</td><td>2022</td></tr>
          <tr><td><i>The Gown</i> - Jo Farb Hernandez Gallery, San Jose, CA </td><td>2022</td></tr>
          <tr><td><i>The Dinner Date</i> - Jo Farb Hernandez Gallery, San Jose, CA </td><td>2020</td></tr>
          <tr><td><i>Reining in Water</i> - Black Gallery, San Jose State University </td><td>2019</td>
          </tr>
          <tr><td><i>Beginning Motherhood</i> – University of Illinois at Chicago</td>
            <td>1998</td>
          </tr>
        </table>
      </div>
      <h3>Selected Group Exhibitions</h3>
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <colgroup>
            <col span='1' className={styles.col1} />
            <col span='1' className={styles.col2} />
          </colgroup>
          <tr><td>Jewelry for Giants – Felix Kulpa Gallery, Santa Cruz, CA </td><td>2023</td></tr>
          <tr><td>Women of the Foundry – Felix Kulpa Gallery, Santa Cruz, CA </td><td>2023</td></tr>
          <tr><td>SculptureWalk Sioux Falls – Sioux Falls, SD </td><td>2023</td></tr>
          <tr><td>Downtown Sculpture Walk – Johnson City, TN </td><td>2023</td></tr>
          <tr>
            <td>Lift Off - Institute for Contemporary Art – San Jose, CA</td>
            <td>2022</td>
          </tr>
          <tr>
            <td>Art in the Time of Corona – DAB Art, on-line, Los Angeles, CA</td>
            <td>2022</td>
          </tr>
          <tr>
            <td>Baytown Sculpture Trail – Baytown, TX</td>
            <td>2022</td>
          </tr>
          <tr>
            <td>Refamiliarization – Platform Artspace, Berkeley, CA</td>
            <td>2021</td>
          </tr>
          <tr>
            <td>Sculpture Walk – Sioux Falls, SD</td>
            <td>2021</td>
          </tr>
          <tr>
            <td>Sculpture Walk Downtown Hutchinson – Hutchinson, KS</td>
            <td>2020</td>
          </tr>
          <tr>
            <td>J. Crew Collection – San Jose State University – Herbert Sanders
              Gallery
            </td>
            <td>2020</td>
          </tr>
          <tr>
            <td>Art on the Town – Mankato, MN</td>
            <td>2019</td>
          </tr>
          <tr>
            <td>SculptureWalk – Sioux Falls, SD</td>
            <td>2018</td>
          </tr>
          <tr>
            <td>Castlegar Sculpturewalk – Castlegar, British Columbia</td>
            <td>2018</td>
          </tr>
          <tr>
            <td>River City Sulptures on Parade – Mason City, IA</td>
            <td>2017</td>
          </tr>
          <tr>
            <td>SculptureWalk – Sioux Falls, SD</td>
            <td>2016</td>
          </tr>
          <tr>
            <td>Sculpture for All – Nacogdoches, TX</td>
            <td>2015</td>
          </tr>
          <tr>
            <td>Art on the Street – Colorado Springs, CO</td>
            <td>2013</td>
          </tr>
          <tr>
            <td>SculptureWalk – Sioux Falls, SD</td>
            <td>2012</td>
          </tr>
          <tr>
            <td>Avera McKennan Sculpture Walk – Sioux Falls, SD</td>
            <td>2012</td>
          </tr>
          <tr>
            <td>Urban Trees – San Diego, CA</td>
            <td>2010</td>
          </tr>
          <tr>
            <td>Urban Trees – San Diego, CA</td>
            <td>2008</td>
          </tr>
          <tr>
            <td>Urban Trees – San Diego, CA</td>
            <td>2007</td>
          </tr>
          <tr>
            <td>Bradley University Student Show – Peoria, IL</td>
            <td>1995</td>
          </tr>
        </table>
      </div>
      <h3>Selected Permanent Public Collections</h3>
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <tr><td>Washington Pavilion – Sioux Falls, South Dakota</td></tr>
          <tr><td>Transit Center – City of Durango, Colorado</td></tr>
          <tr><td>Hanna Springs Sculpture Garden – Lampasas, Texas</td></tr>
          <tr><td>Triangle Park – City of Imperial Beach, California</td></tr>
          <tr><td>Sarah’s Vineyard – Gilroy, California</td></tr>
          <tr><td>Complete Dental Care – Sioux Falls, South Dakota</td></tr>
          <tr><td>Magee Women’s Hospital – Pittsburgh, Pennsylvania</td></tr>
        </table>
      </div>
      <h3>Technical Skills</h3>
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <tr><td>Aluminum, steel and stainless-steel fabrication</td></tr>
          <tr><td>Stone carving</td></tr>
          <tr><td>Bronze and aluminum casting</td></tr>
          <tr><td>Mold making in silicone, latex and plaster</td></tr>
          <tr><td>Epoxy resins, fiberglass, paper experience</td></tr>
          <tr><td>Laser Cutting</td></tr>
        </table>
      </div>
      <h3>Teaching Experience</h3>
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <tr><td>Evergreen Valley Community College – Adjunct Professor </td><td>2023-Present</td></tr>
          <tr><td>San Jose State University</td><td>2021-2022</td></tr>
          <tr><td>Sakamoto Elementary School</td><td>2008-2010</td></tr>
        </table>
      </div>
    </div>
  )
}
