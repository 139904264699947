import { useState, useEffect, useRef } from 'react'
import styles from './Carousel.module.css'

function Tile ({ image }) {
  return (
    <img className={styles.previewImage} src={`/assets/${image.src}`} alt='' />
  )
}

export default function Carousel ({ images }) {
  const [currentImage, setCurrentImage] = useState(images[0])
  const ref = useRef()
  const changeImage = (image, e) => {
    setCurrentImage(image)
    const target = e.target.nodeName === 'IMG'
      ? e.target.parentElement
      : e.target
    ref.current.scroll({
      left: target.offsetLeft - ref.current.offsetWidth / 2 + target.offsetWidth / 2,
      top: target.offsetTop,
      behavior: 'smooth'
    })
  }

  useEffect(() => {
    if (!ref.current) { return }
    if (ref.current.scrollWidth > ref.current.offsetWidth) {
      ref.current.style.justifyContent = 'left'
    } else {
      ref.current.style.justifyContent = 'center'
    }
  })
  return (
    <div className={styles.container}>
      <div className={styles.carousel}>
        <div className={styles.imageHolder}>
          <img
            className={styles.imageView}
            src={`/assets/${currentImage.src}`}
            alt=''
          />
        </div>
        <div className={styles.previewContainer} ref={ref}>
          {images.map(image => (
            <div
              className={styles.previewHolder}
              onClick={e => changeImage(image, e)}
              key={image.src}
            >
              <Tile image={image} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
